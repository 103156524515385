import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';
import Code from '@local/components/typography/Code';
import { MainCourseTitle } from '@local/components/typography/titles';
import BasicInfo from '@local/components/course/BasicInfo';
import CourseForm from '@local/components/course/CourseForm';
import CourseContent from '@local/components/course/CourseContent';
import Requirements from '@local/components/course/Requirements';
import Place from '@local/components/course/Place';
import PriceList from '@local/components/course/PriceList';
import PriceCard from '@local/components/course/PriceCard';
import TableOfContents from '@local/components/course/TableOfContents';

const Ponuka = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>
                    Základy programovania (v JavaScripte) - {data.site.siteMetadata.title}
                </title>
            </Helmet>
            <Layout>
                <MainCourseTitle>Základy programovania v JavaScripte</MainCourseTitle>

                <Typography>
                    Toto je 15 dielne školenie určené pre úplných začiatočníkov v programovaní. Je
                    vhodné pre ľudí, ktorí sa chcú naučiť programovať alebo potrebujú z nejakého
                    dôvodu poznať JavaScript k svojej práci (napríklad pre písanie testovacích
                    scenárov, skriptov pre GTM, prípadne len viac rozumieť práci programátorov)
                </Typography>

                <TableOfContents />

                <BasicInfo>
                    <Typography>
                        Kurz je určený pre ľudí, ktorí nemajú žiadne alebo len malé skúsenosti s
                        programovaním. Na začiatku si povieme, čo to programovanie vôbec je, čo
                        obnáša a keďže sa budeme učiť JavaScript, tak sa budeme špeciálne venovať aj
                        vysvetleniu základných princípov a technológií, na ktorých stojí web.
                        Následne sa budeme postupne učiť jednotlivé prvky jazyka JavaScript.
                    </Typography>

                    <Typography>
                        Mojím cieľom je účastníkov naučiť základy programovania, naučiť ich
                        algoritmizovať (rozkladať problémy na menšie časti) a základy jazyka
                        natoľko, aby sa mohli ďalej rozvíjať samostatne a prípadne vytvárať
                        jednoduché stránky alebo skripty. Z toho dôvodu vysvetľujem iba podmnožinu
                        jazyka, ktorá nám umožní sa sústrediť sa na podstatné veci a napredovať.
                        Rozhodne nejde o komplexný kurz JavaScriptu, kde vysvetľujem každú jeho
                        nuansu a syntaktickú možnosť.
                    </Typography>

                    <Typography>
                        Upozorňujem, že nakoľko sa sústreďujeme na základy programovania, tak kurz
                        je naozaj vhodný pre neprogramátorov. Ak už nejaký iný programovací jazyk
                        ovládate a len sa chcete naučiť JavaScript, tak toto nie je kurz pre vás.
                    </Typography>
                </BasicInfo>

                <CourseForm>
                    <Typography>
                        Nejedná sa o jedno veľké školenie, ale o 15 samostatných lekcií, z ktorých
                        každá trvá zhruba tri hodiny. Hoci je nižšie uvedený približný harmonogram,
                        tak tento je len orientačný a tempo (a prípadne témy) prispôsobujem tomu,
                        ako rýchlo vedia účastníci napredovať.
                    </Typography>

                    <Typography>
                        Keďže málokto má doma k dispozícii dostatok voľného času, tak účastníkom
                        nedávam žiadne povinné domáce úlohy. Podľa potreby však občas zaradím
                        precvičovaciu lekciu, kde sa nebudeme učiť nové veci, ale len si na
                        viacerých príkladoch precvičíme už skôr naučené znalosti.
                    </Typography>

                    <Typography>
                        Školenia robím formou workshopu, čo znamená, že od uchádzačov vyžadujem, aby
                        mali k dispozícii vlastný počítač a počas školenia budú programovať.
                        Zvyčajne najskôr vysvetlím nejakú teóriu, ukážem príklad, ktorý si odpíšu a
                        potom zadávam úlohu, ktorú musia vypracovať. Počas ich práce chodím pomedzi
                        nich a s každým konzultujem postup a navrhnuté riešenie.
                    </Typography>

                    <Typography>
                        Úlohy zadávam tak, aby ich vypracovanie nezaberalo dlhý čas. Zároveň sa
                        snažím pôsobiť prívetivo a skôr ako partner pri programovaní, než učiteľ,
                        aby som nevytváral účastníkom stres. Koniec koncov je cieľom sa niečo naučiť
                        a nie je to súťaž o najrýchlejšie alebo najlepšie riešenie.
                    </Typography>

                    <Typography>
                        Školenie je najlepšie absolvovať postupne tempom 1 - 2 lekcie týždenne.
                        Teoreticky sa vieme dohodnúť aj na intenzívnejšej forme, ale neodporúčam to,
                        pretože efektivita pre účastníkov je vtedy nižšia, nakoľko je toho na nich
                        veľa a nestíhajú si nové znalosti dostatočne pevne zapamätať.
                    </Typography>
                </CourseForm>

                <CourseContent>
                    <ul>
                        <li>
                            Úvod do programovania a algoritmizácie, základy webu a HTML, náš prvý
                            program
                        </li>
                        <li>Základy webu (CSS). Čo sú funkcie. Robot Karel</li>
                        <li>
                            Cyklus <Code>while</Code>, čo sú to premenné a operátory
                        </li>
                        <li>Parametre funkcií, podmienky, anonymné funkcie, callback</li>
                        <li>
                            Funkcie vracajúce hodnotu, prepojenie na formuláre, cyklus{' '}
                            <Code>while</Code>
                        </li>
                        <li>Príklady na precvičovanie 1 (algoritmizácia)</li>
                        <li>
                            Podmienky podrobnejšie, rozdelenie premenných na typy, objekt,{' '}
                            <Code>this</Code>
                        </li>
                        <li>Príklady na precvičovanie 2 (anonymné funkcie a cykly)</li>
                        <li>Prepojenie JavaScriptu s HTML pomocou selektorov a event handlerov</li>
                        <li>Príklady na precvičovanie 3 (objekty, factory funkcia)</li>
                        <li>
                            Cyklus <Code>do...while</Code>, cyklus <Code>for</Code>, predčasné
                            ukončenie cyklu, príkaz <Code>switch</Code>
                        </li>
                        <li>Polia</li>
                        <li>
                            Udalosti stlačenia klávesy, viacrozmerné polia, CSS. Vlastná verzia
                            robota Karla
                        </li>
                        <li>Automatizované testovanie</li>
                        <li>
                            Záverečná lekcia podľa potreby: buď precvičovanie a dovysvetľovanie
                            alebo pokročilejšie a novšie prvyk jazyka a tipy na ďalšie vzdelávanie
                        </li>
                    </ul>
                </CourseContent>

                <Requirements>
                    <ul>
                        <li>Vlastný notebook alebo počítač s moderným prehliadačom</li>
                        <li>
                            Nie je to nutné, ale veľmi to zrýchli prvú lekciu, ak si účastníci
                            dopredu sami nainštalujú:
                            <ul>
                                <li>
                                    nejaký editor kódu (napr.{' '}
                                    <Link to="https://code.visualstudio.com/">VS Code</Link>)
                                </li>
                                <li>
                                    prostredie pre spúšťanie skriptov{' '}
                                    <Link to="https://nodejs.org/en/">Node.js</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Requirements>

                <Place>
                    <Typography>Vo vašej firme alebo online.</Typography>
                </Place>

                <PriceList>
                    <PriceCard where="U vás" howMany="Najviac 5 účastníkov" price="4 500,- €" />
                    <PriceCard where="U vás" howMany="Najviac 10 účastníkov" price="5 250,- €" />
                    <PriceCard where="Online" howMany="Najviac 5 účastníkov" price="4 050,- €" />
                </PriceList>
            </Layout>
        </>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;

export default Ponuka;
